import { navigate } from "gatsby"

import { inlineLabels } from "../components/ProductWrapper/mockedData"

export const getBlogUrl = url =>
  `/blog/${url.split("https://account.hubblecontacts.com/blogs/hubble/")[1]}`

export const getInlineGlassesPrescription = (prescription, type) =>
  Object.entries(prescription)
    .map(
      ([eye, values]) => {
        if(eye == 'pd') {

          // pdValue
          if (!!values) {
            return `${inlineLabels[eye]}: ${values}`
          }
          // return `${inlineLabels[eye]}: ${values}`
        } else {
          return `${inlineLabels[eye]}: ${getInlinePrescription(values, type)}`
        }

        // return `${inlineLabels[eye]}: ${getInlinePrescription(values)}`
    })
    .join("</br>")

export const getInlinePrescription = (values, type) =>
  Object.entries(values)
    .filter(([key, value]) => value)
    .reduce(
      (result, [key, value]) => [...result,
        ((type?.value !== "readers" && value) ||
        (type?.value === "readers" && ((value !== "0" && value !== "+0.0") || (key === "sphere" && value))))
        ? `${inlineLabels[key]} = ${value}` : ""],
      []
    )
    .filter((value) => value)
    .join(", ")

export const navigateWithFilters = (path, filters) => {
  const params = new URLSearchParams("")

  Object.entries(filters).map(
    ([filterKey, filterArray]) =>
      filterArray.length && params.set(filterKey, filterArray.join(","))
  )

  const newUrl = `${path}?${params.toString()}`

  if (window !== undefined)  window.history.replaceState(null, null, newUrl)
}

export const colors = {
  glasses: "orange",
  sunglasses: "green",
  contacts: "purple",
}

export const selectDefaultValue = (options, value) => {
  return options ? options.find(option => option.value === value) : ""
}

export const getValues = (options, value, returnValue) => {
  const optionsList =  Array.isArray(options) ? options : [options]
  return optionsList?.map((el) => (el.value === value) && el[returnValue])?.filter((el) => !!el)[0]
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const isValidEmail = email => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)

export const getCadence = (days) => {
  let cadence = "Every "

  if (days / 7 > 4)
    cadence += (days / 28).toFixed(0) + " Months"
  else
    cadence += (days / 7).toFixed(0) + " Weeks"

  return cadence
}
