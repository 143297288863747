export const selectOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
]

export const inlineLabels = {
  rightEye: "Right (OD)",
  leftEye: "Left (OS)",
  baseCurve: "B.C.",
  diameter: "D",
  power: "P",
  axis: "AXS",
  sphere: "SPH",
  cylinder: "CYL",
  pd: "PD",
  addPower: "A.P."
}
