import { useState, useEffect } from "react"
import { useWindowWidth } from "../services/hooks"
import useIsLifemart from '../components/Lifemart/hooks/useIsLifemart'
import useIsMilitary from '../components/Military/hooks/useIsMilitary'

export const useNewRxFlow = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isNewRx, setIsNewRx] = useState(true)
  const windowWidth = useWindowWidth()
  const isMilitary = useIsMilitary()
  const isLifemart = useIsLifemart()
  const isAffiliate = isMilitary || isLifemart

  useEffect(() => {
    setIsMobile(windowWidth < 578)
  }, [windowWidth])

  // const checkABTests = (retries = 0) => {
  //   if (typeof window !== "undefined" && window?.abtesting) {
  //     if (window?.abtesting?.tests?.hubble) {
  //       setIsNewRx(true)
  //     }
  //   } else if (retries < 50) {
  //     setTimeout(() => checkABTests(retries+1), 100)
  //   }
  // }

  // useEffect(() => {checkABTests()}, [])

  return isNewRx && isMobile
}

export default useNewRxFlow
