import { useState, useEffect } from 'react'
import { useCookies } from "react-cookie"

const useIsMilitary = () => {
    const [isMilitary, setIsMilitary] = useState(false)
    const [cookies] = useCookies(["user-jwt"])
    const userToken = cookies["user-jwt"]

    useEffect(() => {
        const isMilitaryFromStorage = !!localStorage.getItem("isMilitary") && !userToken
        setIsMilitary(isMilitaryFromStorage)
     }, [userToken])

     return isMilitary
}

export default useIsMilitary