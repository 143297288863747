import { useState, useEffect } from 'react'
import { useCookies } from "react-cookie"

const useIsLifemart = () => {
    const [isLifemart, setIsLifemart] = useState(false)
    const [cookies] = useCookies(["user-jwt"])
    const userToken = cookies["user-jwt"]

    useEffect(() => {
        const isLifemartFromStorage = !!localStorage.getItem("isLifemart") && !userToken
        setIsLifemart(isLifemartFromStorage)
     }, [userToken])

     return isLifemart
}

export default useIsLifemart