export const promoStart = process.env.GATSBY_PROMO_START || '11/20/23';
export const promoEnd = process.env.GATSBY_PROMO_END || '12/4/23';

export const usePromo = (startDate, endDate, orderDate = null) => {
  const date = orderDate || new Date();
  const promoStart = new Date(startDate)
  const promoEnd = new Date(new Date(endDate).setHours(23, 59, 59, 999))
  return false
  return (date > promoStart && date < promoEnd)
}

export default usePromo;