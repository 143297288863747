import { useState, useEffect } from "react"
import { useWindowWidth } from "../services/hooks"

export const useNewRxFlowDesktop = () => {
  const [isNewRxDesktop, setIsNewRxDesktop] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 578)
  }, [windowWidth])

  // const checkABTests = (retries = 0) => {
  //   if (typeof window !== "undefined" && window?.abtesting) {
  //     if (window?.abtesting?.tests?.hubble) {
  //       setIsNewRxDesktop(true)
  //     }
  //   } else if (retries < 50) {
  //     setTimeout(() => checkABTests(retries+1), 100)
  //   }
  // }

  // useEffect(() => {checkABTests()}, [])

  return isNewRxDesktop && !isMobile
}

export default useNewRxFlowDesktop
